/* DEFAULT TO MOBILE SETUP */

.menuItemContainer {
	position: absolute;
	top: 72px;
	left: 0px;
	width: 100%;
	height: calc(100% - 72px);
	overflow: scroll;
}

.menuItem {
	position: relative;
	line-height: 50px;
	text-align: center;
	height: 50px;
	width: calc(100% - 40px);
	margin-left: 20px;
	margin-top: 20px;
}

.menuItemIcon {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 30px;
	height: 30px;
	/*background-color: blue;*/
}

.menuChainIcon {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	margin-right: 10px;
	vertical-align: middle;
}

.menuItemLabel {
	position: absolute;
	left: 70px;
	top: 0px;
	width: calc(100% - 80px);
	line-height: 50px;
	text-align: left;
}

.connectWalletButton {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
	margin-top: 20px;
    width: calc(100% - 146px);/*154px;*/
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.selectChain {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
	margin-top: 20px;
    width: 82px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.downIconChain {
    position: absolute;
    right: 10px;
    top: 19px;
    height: 12px;
    width: 12px;
}

.lightLine {
	position: absolute;
	width: 1px;
	left: 50px;
	height: 100%;
}

.socialIcon {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.footerItem {
	margin-left: 20px;
	margin-bottom: 20px;
	width: calc(100% - 40px);
}


/* CHAIN SELECTION DROP DOWN MENU */

.chainSelectorMenu {
	position: absolute;
	top: 160px;
	left: 20px;
	width: calc(100% - 40px);
	height: 152px;
}

.disconnectSelectorMenu {
	position: absolute;
	top: 160px;
	left: 20px;
	width: calc(100% - 40px);
	height: 50px;
}

.chainSelectorListItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: relative;
}


.airdropIndicator {
	display: inline-block;
	margin-left: 10px;
	height: 20px;
	width: 48px;
	vertical-align: middle;
	line-height: 20px;
	text-align: center;
}

.hiddenWallet {
	position: absolute;
	display: none;
}


/* LARGE DEVICES LIKE IPADS */
@media only screen and (min-width: 800px) {
/*	.menuItemContainer {
		top: 92px;
		height: calc(100% - 92px);
	}*/
}

/* DESKTOP */
@media only screen and (min-width: 1200px) {
}
