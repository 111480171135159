

.countdownContainer {
	position: relative;
	margin-left: calc(50% - 145px);
	width: 290px;
	height: 60px;
}

.coundownItem {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
	width: 60px;
	height: 65px;
}

.timeItem0 {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 40px;
	width: 29px;
	text-align: center;
	line-height: 40px;
}

.timeItem1 {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 40px;
	width: 29px;
	text-align: center;
	line-height: 40px;
}

.timeLabel {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 20px;
	line-height: 20px;
	text-align: center;
}