

.nftSimulator {
	margin-left: 20px;
	margin-top: 20px;
	width: 360px;
	height: 460px;

}

.themeItemNFT {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    margin-top: 0px;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.themeItemNFT:last-child {
    margin-right: 20px;
}

.themeListNFT {
    position: relative;
    margin-left: 2px;
    margin-top: 30px;
    margin-bottom: 0px;
    width: calc(100% - 4px);
    height: 180px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}

.closeButton {
    display: none;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    text-align: center;
    line-height: 50px;
}