

.popOverContainer {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 260px;
	height: 50px;
	animation: 0.5s linear popIn;
}

@keyframes popIn {
	from { right: -260px; }
	to { right: 20px; }
}

@keyframes popOut {
	from { right: 20px; display: block; }
	to { right: -260px; }
}