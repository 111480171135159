.gridContainer {
	margin-top: 0px;
	width: 100%;
}

.gridItem {
	display: inline-block;
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100vw - 42px);
	height: calc(100vw + 58px);
	vertical-align: top;
}

.innerGridItem {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.gridItem:hover .innerGridItem{
	transform: rotateY(180deg);
}

.gridItemFront, .gridItemBack {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gridItemFront {
}

.gridItemBack {
	transform: rotateY(180deg);
}

/* FRONT SIZE */

.gridImage {
	position: absolute;
	width: calc(100vw - 42px);
	height: calc(100vw - 42px);
}

.gridItemTitle {
	position: absolute;
	left: 20px;
	bottom: 50px;
	height: 30px;
	line-height: 30px;
	width: calc(100% - 40px);
}

.gridItemSubtitle {
	position: absolute;
	left: 20px;
	bottom: 20px;
	height: 20px;
	line-height: 20px;
	width: calc(100% - 40px);
}


/* BACK SIDE */

.qrCode {
	position: absolute;
	left: 20px;
	bottom: 203px;
	height: calc(100% - 223px);
	width: calc(100% - 40px);
	object-fit: contain;
}

.domainLink {
	position: absolute;
	bottom: 161px;
	line-height: 30px;
	width: calc(100% - 40px);
	text-align: center;
	left: 20px;
}

.reloadDomainButton {
	position: absolute;
	bottom: 121px;
	left: calc(50% - 60px);
	width: 120px;
	height: 30px;
	text-align: center;
	line-height: 30px;
}

.marketplaceLabel {
	position: absolute;
	bottom: 91px;
	width: calc(100% - 40px);
	text-align: center;
	left: 20px;
}

.marketplaceListContainer {
	position: absolute;
	bottom: 20px;
	left: 20px;
	height: 50px;
	width: calc(100% - 40px);
}

.marketplaceItem {
	display: inline-block;
	position: relative;
	width: 33.33%;
	height: 50px;
	vertical-align: bottom;
}

.marketplaceItemIcon {
	position: absolute;
	top: 10px;
	width: 100%;
	height: 30px;
	object-fit: contain;
}

.marketplaceLin0 {
	position: absolute;
	top: 0px;
	left: 33.33%;
	width: 1px;
	height: 100%;
}

.marketplaceLin1 {
	position: absolute;
	top: 0px;
	right: 33.33%;
	width: 1px;
	height: 100%;
}



.tinyInlineImage {
	display: inline-block;
	margin-left: 10px;
	width: 15px;
	height: 15px;
	vertical-align: middle;
}





@media only screen and (min-width: 450px) {

    .gridItem {
        width: calc(50vw - 34px);
        height: calc(50vw + 58px);
    }

    .gridImage {
        width: calc(50vw - 34px);
        height: calc(50vw - 34px);
    }
}

@media only screen and (min-width: 690px) {

    .gridItem {
        width: calc(33.33vw - 28.6666666667px);
        height: calc(33.33vw + 62.3333333333px);
    }

    .gridImage {
        width: calc(33.33vw - 28.6666666667px);
        height: calc(33.33vw - 28.6666666667px);
    }
}


@media only screen and (min-width: 800px) {
    .gridItem {
        width: calc(50vw - 184px);
        height: calc(50vw - 92px);
    }

    .gridImage {
        width: calc(50vw - 184px);
        height: calc(50vw - 184px);
    }

}

@media only screen and (min-width: 1000px) {
    .gridItem {
        width: calc(33.33vw - 128.6666666667px);
        height: calc(33.33vw - 38.6666666667px);
    }

    .gridImage {
        width: calc(33.33vw - 128.6666666667px);
        height: calc(33.33vw - 128.6666666667px);
    }
}


@media only screen and (min-width: 1200px) {
	.gridItem {
		width: 271.333333333px;
		height: 363.333333333px;
	}

	.gridImage {
        width: 271.333333333px;
        height: 271.333333333px;
    }

}