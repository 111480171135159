.domainListContainer {
	margin-top: 20px;
	width: 100%;
}

.domainListItem {
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 50px;
}

.domainListSelector {
	position: absolute;
	left: 0ox;
	top: 0px;
	width: 50px;
	height: 100%;
}

.domainListLabel {
	position: absolute;
	left: 70px;
	top: 2px;
	width: calc(100% - 307px);
	height: 25px;
	line-height: 25px;
	pointer-events: none;
}

.domainExpiryLabel {
	position: absolute;
	left: 70px;
	top: 30px;
	width: calc(100% - 307px);
	height: 15px;
	line-height: 15px;
	pointer-events: none;
}

.editNFTButton {
	position: absolute;
	top: 0px;
	right: 0px;
	padding-left: 20px;
	padding-right: 20px;
	/*width: 100px;*/
	height: 50px;
	line-height: 50px;
	display: none;
}

.editPageButton {
	position: absolute;
	top: 0px;
	right: 102px;
	padding-left: 20px;
	padding-right: 20px;
	/*width: 100px;*/
	height: 50px;
	line-height: 50px;
	display: none;
}

.renewDomainButton {
	position: absolute;
	top: 0px;
	right: 87px;
	padding-left: 20px;
	padding-right: 20px;
	/*width: 100px;*/
	height: 50px;
	line-height: 50px;
	display: none;
}


.mobileEditOptions {
	position: absolute;
	top: 0px;
	left: 51px;
	width: calc(100% - 51px);
	height: 100%;
}

.downLineEdit {
	position: absolute;
	width: 1px;
	height: 100%;
	right: 32px;
}

.listActionsSelectorMenu {
	position: absolute;
    top: 160px;
    right: 20px;
    width: 260px;
    height: 102px;
    display: none;
}

.listSelectorListItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: relative;
}

.listItemIcon {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 30px;
	height: 30px;
	display: none;
}




/* PORTAL */
.priceContainer {
	
}

.continueButton {
	display: inline-block;
	margin-right: 20px;
	width: 100px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.inputTextDataPortal {
	margin-top: 20px;
	margin-bottom: 20px;
	width: calc(100% - 40px);
	height: 30px;
	line-height: 30px;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	color: white;
	font-size: 16px;
	outline:none;
}

.nextButton {
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(50% - 30px);
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.previousButton {
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(50% - 30px);
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.dropDownSelectorPortal {
	position: relative;
	top: auto;
	left: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 50px;
	display: block;
	margin-left: 0px;
	width: 100%;
}

.scrollableContentInPopUp {
    max-height: calc(100vh - 400px);
    min-height: 300px !important;
    overflow: scroll;
}

.scrollableContentInPopUpExtended {
    max-height: calc(100vh - 200px);
    overflow: scroll;
}

.pageLoadingIndicator {
	display: none;
	margin-top: calc(50% - 15px);
	margin-left: calc(50% - 15px);
	width: 30px;
	height: 30px;
}

@media only screen and (min-width: 600px) {

	.editNFTButton {
		display: block;
	}

	.editPageButton {
		display: block;
	}

	.mobileEditOptions {
		display: none;
	}

	.renewDomainButton {
		display: block;
	}
}

