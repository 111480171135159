.floatingConnectorButton {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 50px;
}

.mustConnect {
	display: none;
	position: absolute;
	left: 20px;
	top: calc(50% - 100px);
	width: calc(100% - 40px);
	text-align: center;
}

.forceConnectButton {
	margin-left: calc(50% - 100px);
	width: 200px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

#mainContentContainer {
	display: none;
}