
/* */
.pageTitle {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 52px;
	line-height: 52px;
	text-align: left;
}

/* CART ITEM */

.cartItemContainer {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 122px;
}

.cartItemImage {
	position: absolute;
	left: 20px;
	top: 20px;
	width: 82px;
	height: 82px;
	object-fit: contain;
}

.cartItemImageDelimatator {
	position: absolute;
	width: 1px;
	left: 122px;
	height: 100%;
}

.cartItemTitleText {
	position: absolute;
	left: 143px;
	top: 20px;
	width: calc(100% - 213px);
	height: 30px;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


.cartItemImageLogo {
	position: absolute;
	top: calc(50% - 15px);
	left: calc(50% - 15px);
	width: 30px;
	height: 30px;
	object-fit: contain;
}


.cartItemSubtitleText0 {
	position: absolute;
	left: 143px;
	top: 55px;
	width: calc(100% - 163px);
	height: 20px;
	line-height: 20px;
}

.deleteCartItemButton {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	height: 30px;
}

.cartLeftBottomText {
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: 82px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.cartItemDescription {
	position: absolute;
	top: 70px;
	left: 143px;
	width: calc(100% - 163px);
	height: 30px;
}

.additionalBottomSpacer {
	margin-bottom: 20px;
}

/* DROP DOWN */
.dropDownSelectorCart {
    position: absolute;
    bottom: 20px;
    left: 143px;
    width: calc(100% - 163px);
    height: 50px;
    
}

.dropDownInner {
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: transparent;

    color: white;
    font-size: 16px;
    outline: none;
    border: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.lightLineRight {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 32px;
    height: 100%;
}


.lightLineRightDropDown {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 32px;
    height: 100%;
}














/* ORDER SUMMARY */
.orderSummaryContaier {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 300px;
}

.edgedLine {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 1px;
}

.itemsTextBox {
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 30px;
	line-height: 30px;
}

.simpleTextBot {
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 50px;
	line-height: 50px;
}

.simpleTextBotNM {
	position: relative;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 50px;
	line-height: 50px;
}

.extraTopMarginBot {
	margin-top: 20px;
}

.subTextBot {
	position: relative;
	margin-left: 20px;
	margin-top: 0px;
	width: calc(100% - 40px);
	height: 20px;
	line-height: 20px;
	display: none;
}

.innerRightText {
	position: absolute;
	right: 0px;
	top: 0px;
	text-align: right;
}

.paymentButton {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	height: 50px;
	line-height: 50px;
	text-align: center;
}













/* MOBILE CHECKOUT */
.mobileSummaryBar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    background-color: black;
}

.mobileSummaryBarCart {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 120px;
    background-color: black;
}

.mobileSummaryBarCartAlt {
	position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 120px;
    background-color: black;
}


.mobilePaymentButton {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 150px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.mobileUSDPrice {
	position: absolute;
	left: 20px;
	bottom: 40px;
	line-height: 30px;
	width: calc(100% - 200px);
	height: 30px;
}

.mobileCryptoPrice {
	position: absolute;
	left: 20px;
	bottom: 20px;
	line-height: 20px;
	width: calc(100% - 200px);
	height: 20px;
}


.dropDownSelectorCartMobile {
	position: absolute;
	left: 20px;
	bottom: 90px;
	height: 50px;
	width: calc(100% - 40px);
}
















/* CHECKOUT STATUS */
/*.checkoutStatusContainer {
	position: absolute;
	left: 20px;
	top: 20px;
	height: calc(100% - 40px);
	width: calc(100% - 40px);
}

*/

.checkoutStatusContainer {
	position: relative;
	top: 50%;
	margin-left: 20px;
    transform: translateY(-50%);
    width: calc(100% - 40px);
    max-height: calc(100vh - 40px);
}



.stepTitle {
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 90px);
	height: 30px;
	line-height: 30px;
	padding-left: 50px;
}

.stepNumber {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 30px;
	height: 30px;
	fill: white;
}

path {
	transform: scale(0.3);
}

.checkoutStatusText {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
}

.helpButton {
	position: absolute;
	right: 0px;
	top: 11px;
	width: 30px;
	height: 30px;
}

.emptyCart {
	display: none;
	position: absolute;
	left: 20px;
	top: calc(50% - 50px);
	width: calc(100% - 40px);
	text-align: center;
}


@media only screen and (max-width: 425px) {
	.cartItemDescription {
		top: 50px;
	}
}
/*
@media only screen and (max-width: 800px) {
	.cartItemImage {
		left: 0px;
		top: 0px;
		width: 82px;
		height: 82px;
		object-fit: contain;
	}

	.cartItemImageDelimatator {
		position: absolute;
		width: 1px;
		left: 82px;
		height: 100%;
	}

	.cartItemTitleText {
		left: 102px;
		top: 10px;
		width: calc(100% - 172px);
	}


	.cartItemDescription {
		left: 102px;
		top: 45px;
		width: calc(100% - 172px);
	}

	.cartItemContainer {
		height: 82px;
	}

	.deleteCartItemButton {
		top: 15px;
		right: 15px;
	}
}
*/

.viewDomainButton {
	display: inline-block;
	width: calc(50% - 15px);
/*	padding-left: 15px;
	padding-right: 15px;*/
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.viewDomainButtonEx {
	display: inline-block;
	width: calc(50% - 15px);
/*	padding-left: 15px;*/
	margin-right: 20px;
/*	padding-right: 15px;*/
	height: 50px;
	line-height: 50px;
	text-align: center;
}

@media only screen and (min-width: 800px) {
	.mobileSummaryBar {
		left: 300px;
		width: calc(100% - 300px);
	}

	.mobileSummaryBarCartAlt {
		left: 300px;
		width: calc(100% - 300px);
	}

	.mobileSummaryBarCart {
		
	}

	.checkoutStatusContainer {
		margin-left: calc(50% - 250px);
		width: 500px;
	}
}

@media only screen and (min-width: 1200px) {

	.mobileSummaryBar {
		display: none;
	}

	.mobileSummaryBarCartAlt {
		display: none;
	}

	.mobileSummaryBarCart {
		display: none;
	}

	

}














