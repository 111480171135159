/* GLOBAL SETUP */



* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.logoFont {
    font-family: 'Quantico', sans-serif;
}

.courierFont {
    font-family: 'Courier Prime', monospace;
}


html, body { height:100%; font-size: 16px; background-color: black }


/* FONT SIZES */

.titleLargeFont {
    font-size: 50px;
}

.secondaryFont {
    font-size: 30px;
}

.largeFont {
    font-size: 32px;
}

.largeFontFlex {
    font-size: 32px;
}

.mediumFont {
    font-size: 24px;
}

.extraMediumFont {
    font-size: 20px;
}

.regularFont {
    font-size: 18px;
}

.smallFont {
    font-size: 14px;
}

.tinyFont {
    font-size: 12px;
}

/* BACKGROUND COLORS */
.blackBackground {
    background-color: black;
}

.lightGreenBackground {
    background-color: #00ffa2;
}

.charcolBackground {
    background-color: #22272b; /*#303030;*/
}

.lightBackgroundColor {
    background-color: rgba(255, 255, 255, 0.25)
}

.redBackground {
    background-color: #400000;
}

.lightRedBackground {
    background-color: #ff7373;
}

.darkBlueBackground {
    background-color: #001f50;
}

.darkGreenBackground {
    background-color: #00503b;
}

.deepPurpleBackground {
    background-color: #440050;
}

.purpleBackground {
    background-color: #8494f8;
}

.gradientBackground {
    background-image: linear-gradient(to left, #03e1ff, #00ffa2);
}

.gradientBackgroundSharp {
    background-image: linear-gradient(to left, #d267ff, #03e1ff);
}


.goldBackground {
    background-color: #bb8126;
}

.whiteBackground {
    background-color: white;
}

.almostWhiteBackground {
    background-color: rgba(255, 255, 255, 0.85);
}

.lightGoldBackground {
    background-color: #ffe659;
}

.bluePurpleGradientBackground {
    background:  -webkit-linear-gradient(right, #d267ff, #03e1ff);
}

.gradientBackgroundBlack {
    background:  -webkit-linear-gradient(top, #292929, #151515);
}

.gradientBlackToTransparent {
    background:  -webkit-linear-gradient(left, black, rgba(0,0,0,0.75), transparent);
}

/* TEXT COLORS */
.whiteText {
    color:  white;
}

.lightGreenText {
    color: #00ffa2;
}

.blackText {
    color: black;
}

.blueGreenGradientText {
    background: -webkit-linear-gradient(right, #03e1ff, #00ffa2); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.blueGreenGradientTextReverse {
    background: -webkit-linear-gradient(left, #03e1ff, #00ffa2); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.redishGradientText {
    background: -webkit-linear-gradient(right, #ff7373, #d267ff); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.blueBlueGradient {
    background: -webkit-linear-gradient(right, #d267ff, #03e1ff); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.orangeGradient {
    background: -webkit-linear-gradient(right, #ff7373, #ffe659); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.goldText {
    color: #d267ff;
}

.redText {
    color:  #ff7373
}

.purpleText {
    color: #8494f8;
}

.orangeText {
    color: #f4ad65;
}

.yellowText {
    color: #f9da7b;
}

.greenText {
    color: #00ffa2;
}




/* BORDER CURVES */
.standardCurve {
    border-radius: 25px;
    overflow: hidden;
}

.standardCurveNonHidden {
    border-radius: 25px;
}

.standardCurveTop {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    overflow: hidden;
}

.standardCurveBottom {
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    overflow: hidden;
}

.lightGreenBorder {
    border: 1px solid #00ffa2;
}

.goldBorder {
    border: 1px solid #ff7373;
}

.goldBorderBottom {
    border-bottom: 1px solid #d267ff;
}


/* BORDER COLOR */
.lightBorder {
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.bottomLightBorder {
    border-bottom: 1px solid rgba(255,255,255,0.25);
}

.topLightBorder {
    border-top: 1px solid rgba(255,255,255,0.25);
}

.leftLightBorder {
    border-left: 1px solid rgba(255,255,255,0.25);
}

.rightLightBorder {
    border-right: 1px solid rgba(255,255,255,0.25);
}

.exceptBottomLightBorder {
    border: 1px solid rgba(255,255,255,0.25);
    border-bottom: none;
}

.whiteBorder {
    border: 1px solid white;
}

.blackBorder {
    border: 1px solid black;
}

/* CURSOR */

.clickableItem {
    cursor: pointer;
}

.clickableItem:hover {
    animation: 0.5s linear hoverAnimationMobile;
    /*background-color: #001f50;*/
}

@keyframes hoverAnimationMobile {
    0% { background-color: #001f50; }
    100% { background-color: auto; }
}

.clickableItemNoHover {
    cursor: pointer;
}


.greenClickable {
    cursor: pointer;
}

.greenClickable:hover {
    animation: 0.5s linear greenHoverAnimationMobile;
}

.subtleClickable {
    cursor: pointer;
}

.subtleClickable:hover {
    animation: 0.5s linear subtleClickAnimation;
}

@keyframes subtleClickAnimation {
    0% { opacity: 0.25; }
    100% { opacity: 1; }
}

@keyframes greenHoverAnimationMobile {
    0% { background-color: #14e096; }
    100% { background-color: auto; }
}

.clickableText {
    cursor: pointer;
}

.clickableText:hover {
    animation: 0.5s linear textHoverAnimationMobile;
}

@keyframes textHoverAnimationMobile {
    0% { background: -webkit-linear-gradient(right, #03e1ff, #00ffa2); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }
    100% { background: auto; }
}


@media only screen and (min-width: 800px) {
    .clickableItem:hover {
        background-color: #001f50;
    }
    .greenClickable:hover {
        background-color: #14e096;
    }
    .subtleClickable:hover {
        animation: none;
        opacity: 0.75;
    }

    .clickableText:hover {
        background: -webkit-linear-gradient(right, #03e1ff, #00ffa2); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
    }

    

}

@media only screen and (max-width: 800px) {
    .largeFontFlex {
        font-size: 24px;
    }
}



.pointer {
    cursor: pointer;
}

/* EXTRA */
.defaultHide {
    display: none;
}


/* TEXT WRAP */
.wrapText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



/* GLOW */
.outerGlow {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.75);
}

.greenGlow {
    box-shadow: 0 0 10px rgba(0, 255, 162, 0.75);
}
