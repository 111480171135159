.hiddenScroll {
    -ms-overflow-style: none; scrollbar-width: none;
}

.hiddenScroll::-webkit-scrollbar {
    display: none;
}

/* TICKER */

.tickerContainer {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
}

.tickerContainer:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.airdropText {
    position: absolute;
    width: 100%;
    top: 5px;
    line-height: 20px;
    text-align: center;
}

.airdropSubText {
    position: absolute;
    width: 100%;
    top: 30px;
    line-height: 15px;
    text-align: center;
}

input:focus {
    outline:none;
}




.socialBar {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
}

.socialFloatingItem {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    width: 55px;
    height: 55px;
}

.floatingSocialImage {
    position: absolute;
    top: 0px;
    left: 12.5px;
    width: 30px;
    height: 30px;
}

.floatSocialLable {
    position: absolute;
    top: 35px;
    left: 0px;
    width: 55px;
    height: 30px;
    line-height: 20px;
    text-align: center;
}



/* SHINE EFFECT */
.shine {
    position: absolute;
    top: 0;
    left: -100px;
    height: 98px;
    width: 100px;
    background: rgba(255, 255, 255, 0.25);
    /*transition: all 0.5s linear;*/
    transform: skewX(20deg) translateX(0);
    animation: shi infinite 3s;

    -webkit-filter: blur(35px);
    -moz-filter: blur(35px);
    -o-filter: blur(35px);
    -ms-filter: blur(35px);
    filter: blur(35px);
}

.shinable {
    overflow: hidden;
}

@keyframes shi {
    0% {transform: skewX(-20deg); left: calc(50% - 250px); background: rgba(255, 255, 255, 0);}
    50% {background: rgba(255, 255, 255, 0.25);}
    100% {transform: skewX(20deg); left: calc(50% + 150px); background: rgba(255, 255, 255, 0);}
}


.shineButton {
    position: absolute;
    top: 0;
    left: -100px;
    height: 98px;
    width: 100px;
    background: rgba(255, 255, 255, 0.25);
    /*transition: all 0.5s linear;*/
    transform: skewX(20deg) translateX(0);
    animation: shiButton infinite 3s;

    -webkit-filter: blur(35px);
    -moz-filter: blur(35px);
    -o-filter: blur(35px);
    -ms-filter: blur(35px);
    filter: blur(35px);
}

@keyframes shiButton {
    0% {transform: skewX(-20deg); left: calc(50% - 250px); background: rgba(255, 255, 255, 0);}
    50% {background: rgba(255, 255, 255, 0.65);}
    100% {transform: skewX(20deg); left: calc(50% + 150px); background: rgba(255, 255, 255, 0);}
}



.fullHeight {
    height: 100%;
}





.searchTitleExplanation {
    position: absolute;
    top: 60px;
    left: 20px;
    width: calc(100% - 40px);
    text-align: center;
}












.firstFullPannel {
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: hidden;
}


.fullPannel {
    overflow-x: hidden;
    position: relative;
    width: 100%;
}

.headerBlock {
    position: relative;
    width: 100%;
    height: 90px;
}

.discordContinueButton {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(50% - 32px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.standardBox {
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 50px;
    line-height: 50px;
    padding-right: 15px;
    padding-left: 15px;
    float: right;
    margin-right: 0px;
    margin-top: 20px;
    cursor: pointer;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.boxImage {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 8px;
}

.mainBoxText {
    top: 0px;
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: 105px;
}

.textBoxTop {
    position: absolute;
    top: 8px;
    height: 20px;
    line-height: 20px;
}

.textBoxBottom {
    position: absolute;
    top: 28px;
    height: 15px;
    line-height: 15px;
}

.logoContainer {
    position: absolute; top: 20px; left: 20px; height: 50px; line-height: 30px; color: white; font-size: 30px;
}

.logoBox {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.logoTextHome {
    position: absolute;
    left: 30px;
    top: 0px;
    line-height: 50px;
    font-family: 'Quantico', sans-serif;
}

@media only screen and (max-width: 360px) {
    .logoTextHome {
        display: none;
    }

    .footerTitle {
        display: none;
    }
}

.innerCC {
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
}


.innerImage {
    position: absolute;
    width: 1000px;
    left: calc(50% - 500px);
    bottom: 0px;
}

.innerContainerVerticalAllign {
    position: relative;
    width: 1200px;
    margin-left: calc(50% - 600px);
/*    margin-left: calc(50% - 600px);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
*/
}


.innerContainer {
    position: relative;
    width: 1200px;
    margin-left: calc(50% - 600px);
}

.homeSearchContainer {
    position: relative;
    margin-top: 20px;
    margin-left: calc(50% - 260px);
    height: 50px;
    width: 400px;
    background-color: green;
    vertical-align: top;

}

.superCurve {
    border-radius: 100px;
}


.searchBarContainer {
    position: absolute;
    height: 80px;
    width: 100%;
    text-align: center;
}

.searchBarContainerSpecial {
    position: absolute;
    height: 80px;
    top: 150px;
    width: 600px;
    left: calc(50% - 300px);
    height: 80px;
}

.searchHomePageContainer {
    position: absolute;
    width: 600px;
    top: 250px;
    left: calc(50% - 300px);
}


.searchOverContainer {
    position: relative;
    height: 80px;
    width: 100%;
    margin-top: 20px;
}


.finalContent {
    position: relative;
}

.comingSoonLock {
    position: absolute;
    top: -5px;
    width: calc(100% + 40px);
    left: -20px;
    height: calc(100% + 13px);
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

/*.blurBackBackground {
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}*/

.comingSoonLockHidden {
    display: none;
}

.lockSpace {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 150px);
    width: 300px;
    height: 50px;
}

.centralizedTextLock {
    position: absolute;
    width: 100%;
    top: 0px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.inlineImageLock {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.inlineImageLockRight {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    vertical-align: middle;
}


@media only screen and (max-width: 800px) {
    .lockSpace {
        top: 15px;
        height: 30px;
        width: 220px;
        left: calc(50% - 110px);
    }

    .centralizedTextLock {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }

    .inlineImageLock {
        height: 14px;
        width: 14px;
    }

    .inlineImageLockRight {
        height: 14px;
        width: 14px;
    }
}




.firstSafeSpace {
    width: 600px;
    margin-left: calc(50% - 300px);
    text-align: center;
}


.homeTitleContainer {
    text-align: center;
}

.frontPageSearchIcon {
    position: absolute;
    left: 20px;
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
}

.belowText {
    margin-top: 20px;
    text-align: center;
}

.frontSearchBar {
    position: absolute;
    width: calc(100% - 100px);
    left: 70px;
    height: 50px;
    top: calc(50% - 25px);
    border: none;
    background-color: transparent;
    color: white;
}



.smallSpacer {
    width: 100%;
    height: 20px;
}

.bigSpacer {
    width: 100%;
    height: 50px;
}

.generalTextType {
    text-align: center;
}

.ytVideo {
    display: block;
    margin-top: 20px;
    width: 600px;
    height: 337.5px;
}

.innerSafe {
    width: 800px;
    margin-left: calc(50% - 400px);
    text-align: center;
}


.roundButtonInnerImg {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    /*border-radius: 30px;*/
}

.resellButton {
    display: inline-block;
    position: relative;
    width: 170px;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 25px;
    line-height: 50px;
    /*box-shadow: 0px 0px 10px 1px rgba(255, 225, 255, 0.5);*/
}


.browserButtonText {
    
    position: absolute;
    left: 30px;
    width: calc(100% - 40px);
    height: 50px;
    line-height: 50px;
    text-align: center;

}




/* FOOTER */


.footer0 {
    position: relative;
    width: 100%;
    height: 90px;
}

.footerDiscord {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.footerTwitter {
    position: absolute;
    bottom: 20px;
    right: 70px;
    width: 30px;
    height: 30px;
}

.footerMedium {
    position: absolute;
    bottom: 20px;
    right: 120px;
    width: 30px;
    height: 30px;
}


.footerLogo {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.footerTitle {
    position: absolute;
    bottom: 20px;
    left: 70px;
    vertical-align: bottom;
    line-height: 30px;
}

.footerImg {
    position: absolute;
    left: calc(50% - 160px);
    bottom: 0px;
    width: 320px;

}

.leftSubtitle {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(50% - 30px);

}

.rightSubtitle {
    position: absolute;
    top: 20px;
    right: 20px;
    width: calc(50% - 30px);
    text-align: right;

}

.footerSubtitle {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    text-align: center;
}

.footer1 {
    position: relative;
    width: 100%;
    height: 190px;
}




/* FAQ */
.faqContainer {
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
}

.faqHeader {
    position: relative;
    width: calc(100% - 70px);
    text-align: left;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 50px;
    border-radius: 25px;
}

.faqInner {
    width: calc(100% + 30px);
    margin-top: 5px;
    padding-top: 20px;
    line-height: 25px;
    display: none;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.faqPlus {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.faqTable {
    text-align: center;
    line-height: 30px;
    height: 30px;
    width: 100%;
    border-collapse: collapse;
}


table, th, td {
    border: 1px solid #ccc;
    min-width: 70px;
}




.wpImg {
    margin-left: calc(50% - 400px);
    width: 800px;
}

.pressImage {
    margin-left: calc(50% - 400px);
    width: 800px;
}


.codeButton {
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    max-width: 180px;
}








/* API */

.codeBoxContainer {
    position: relative;
    width: 100%;
    max-width: 600px;
}

.codeBox {
    position: relative;
    width: 100%;
    height: 150px;
}

.seperatorBlock {
    position: relative;
    width: 100%;
    height: 30px;
    text-align: left;
}

.normalItem {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    width: 100px;
    text-align: center;
}

.consoleBox {
    position: relative;
    width: 100%;
    height: 170px;
}

.lineNum {
    position: absolute;
    width: 50px;
    height: 100%;
    line-height: 19px;
}

.lineCode {
    position: absolute;
    left: 70px;
    width: calc(100% - 90px);
    height: 100%;
    text-align: left;
    line-height: 19px;
}

.consoleCode {
    position: absolute;
    left: 20px;
    width: calc(100% - 40px);
    height: 100%;
    text-align: left;
    line-height: 19px;
}

.codeButton {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    /*box-shadow: 0px 0px 10px 1px rgba(255, 225, 255, 0.5);*/
}



.portalContainer {
    margin-left: calc(50% - 450px);
    width: 900px;
    height: 300px;
    overflow: hidden;
    /*transform: scale(0.5);*/
}


.transferText {
    position: absolute;
    width: calc(100% - 20px);
    height: 100%;
    line-height: 50px;
    text-align: center;

}

.transferSelector {
    position: relative;
    margin-left: calc(50% - 150px);
    width: 300px;
    height: 50px;
    overflow: hidden;
}

.transferInner {
    width: 100%;
    height: 70px;
    overflow: scroll;
    pointer-events: none;
}

.transferOverflow {
    position: relative;
    width: 1800px;
    height: 100%;

}

.transferOption {
    display: inline-block;
    width: 300px;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
}

.portalDropDown {
    position: relative;
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    width: 150px;
    height: 50px;
    color: black;
    border-radius: 50px;
    padding-left: 10px;
}






.emailCollectionContainer {
    position: relative;
    margin-top: 20px;
    margin-left: calc(50% - 300px);
    width: 600px;
    height: 50px;
}

.emailInputContainer {
    position: absolute;
    width: calc(100% - 135px);
    height: 50px;
}

.saveEmailButton {
    position: absolute;
    right: 0px;
    width: 115px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}















.carouselDashLeft {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 10px;
    top: calc(50% - 15px);
}

.carouselDashRight {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: calc(50% - 15px);
}




.carousselContainer {
    margin-left: calc(50% - 533px);
    position: relative;
    width: 100%;
    max-width: 1066px;
    height: 422px;
    overflow: hidden;
}

.caroussel {
    margin-top: 0px;
    margin-left: 50px;
    width: calc(100% - 100px);
    height: 452px;
    overflow: scroll;
    /*pointer-events: none;*/

}

.innerCarousel {
    width: 4508px;
    height: 100%;

}

.carouselSpacer {
    display: none;
}

.carousselItem {
    margin-top: 0px;
    position: relative;
    width: 300px;
    height: 400px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    /*box-shadow: 0px 0px 10px 1px rgba(255, 225, 255, 0.5);*/
}

.carousselItemImg {
    top: 0px;
    left: 0px;
    width: 300px;
    height: 300px;
    position: absolute;
}

.carouselText {
    position: absolute;
    top: 320px;
    left: 20px;
    top: 315px;
    width: 260px;
    height: 50px;
    text-align: left;
}

.carousellLabel {
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 20px;
    line-height: 20px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    background-color: black;
    color: white;
}


.fadeWhiteLeft {
    top: 0px;
    position: absolute;
    width: 50px;
    height: 402px;
    background-image: linear-gradient(to right, black, black, transparent);
}

.fadeWhiteRight {
    top: 0px;
    position: absolute;
    right: 0px;
    width: 50px;
    height: 100%;
    background-image: linear-gradient(to left, black, black, transparent);
}


.unselectableNav {
    pointer-events: none;
    opacity: 0.25;
}





.tiledImage {
    position: relative;
    vertical-align: bottom;
    width: 100%;
    overflow: hidden;
}



.suggestDomain {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 30px;
    line-height: 30px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    background-color: black;
    color: white;
    cursor: pointer;
}

#addWords {
    position: relative;
    width: 100%;
}

.bigLFade {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 10%;
    height: 100%;
    background-image: linear-gradient(to right, black, transparent);
}

.bigRFade {
    top: 0px;
    position: absolute;
    right: 0px;
    width: 10%;
    height: 100%;
    background-image: linear-gradient(to left, black, transparent);
}













.permenantCartButton {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 20px;
    margin-top: 30px;
    float: right;
}







@media only screen and (max-width: 1066px) {

    .carouselSpacer {
        display: inline-block;
        height: 100px;

    }

    .caroussel {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .carousselContainer {
/*        background-color: red;*/
        width: 100vw;
        margin-left: -20px;
    }

    #carLeft {
        left: 0px;
    }

    #carRight {
        right: 0px;
    }

    
}

@media only screen and (max-width: 1000px) {
    
    .innerImage {
        width: 100%;
        left: 0px;
    }
}

@media only screen and (max-width: 800px) {
    


    .carousselItem {
        width: 200px;
        height: 300px;
    }

    .carousselItemImg {
        width: 200px;
        height: 200px;
    }

    .carousselContainer {
        height: 322px;
    }

    .carouselText {
        position: absolute;
        left: 20px;
        top: 215px;
        width: 260px;
        height: 50px;
    }

    .emailCollectionContainer {
        margin-left: 0px;
        width: 100%;
        height: 120px;
    }

    .emailInputContainer {
        width: 100%;
    }
    .saveEmailButton {
        top: 70px;
        width: 100%;
    }

}









@media only screen and (max-width: 920px) {
    .portalContainer {
        margin-left: 0px;
        width: 900px;
        height: 300px;
        /*border: 1px solid red;*/
        transform-origin: left top;
    }
}




@media only screen and (max-width: 1200px) {

    .innerContainerVerticalAllign {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .innerContainer {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

}




@media only screen and (max-width: 800px) {
    .innerSafe {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .wpImg {
        margin-left: 0px;
        width: 100%;
    }

    .pressImage {
        margin-left: 0px;
        width: 300px;
    }

    .browserButton {
        width: 50px;
    }

    .resellButton {
        width: 50px;
    }

    .resellButtonText {
        display: none;
    }

    .browserButtonText { 
        display: none;
    }

    .searchBarContainer {
        height: 50px;
    }   


    

    .ytVideo {
        margin-top: 20px;
        margin-left: 0px;
        width: calc(100vw - 40px);
        height: calc(56.25vw - 22.5px);
    }
}

@media only screen and (max-width: 400px) {
    .pressImage {
        margin-left: 0px;
        width: 100%;
    }
}

.regularFontFrontPage {
    font-size: 18px;
}

.frontMintPageOptionShown {
    display: block;
}

.frontMintPageOptionHidden {
    display: none;
}

.smallFontFrontPage {
    font-size: 14px;
}

@media only screen and (max-width: 650px) {
    .logoContainer {
        left: 0px;
    }

    .logoBox {
        left: 0px;
        top: 10px;
        width: 30px;
        height: 30px;
    }


    .logoTextHome {
        left: 20px;
    }

    .searchHomePageContainer {
        position: absolute;
        width: calc(100% - 40px);
        top: 160px;
        left: 20px;
    }

    .searchBarContainerSpecial {
        position: absolute;
        top: 90px;
        height: 50px;
        width: calc(100% - 40px);
        left: 20px;
    }

    .searchTitleExplanation {
        top: 20px;
    }



}


@media only screen and (max-width: 600px) {
    .firstSafeSpace {
        width: calc(100% - 40px);
        margin-left: 20px;
        height: 50px;
    }

    .titleLargeFont {
        font-size: 30px;
    }

    .logoSpecialFontSize {
        font-size: 24px;
    }

    .secondaryFont {
        font-size: 20px;
    }

    .regularFontFrontPage {
        font-size: 16px;
    }

    .smallFontFrontPage {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    
    .titleLargeFont {
        font-size: 25px;
    }

    .logoSpecialFontSize {
        font-size: 24px;
    }

    .secondaryFont {
        font-size: 18px;
    }

    .regularFontFrontPage {
        font-size: 14px;
    }

    .smallFontFrontPage {
        font-size: 12px;
    }

}  



@media only screen and (max-width: 340px) {
    
    .titleLargeFont {
        font-size: 22px;
    }

    .logoSpecialFontSize {
        font-size: 22px;
    }

    .secondaryFont {
        font-size: 16px;
    }

    .regularFontFrontPage {
        font-size: 12px;
    }

    .smallFontFrontPage {
        font-size: 10px;
    }

}  
