/* MAIN COMPONENTS */
.contentContainer {
	margin-top: 20px;
	margin-left: 20px;
	min-height: 50px;
	width: calc(100% - 40px);
}

/* MOBILE PUBLISHING */
.mobilePublishBar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
}

.mobilePublishButton {
    position: absolute;
    right: 20px;
    top: 0px;
    width: calc(50% - 30px);
    height: 50px;
    text-align: center;
    line-height: 50px;
}

.mobilePreviewButton {
    position: absolute;
    left: 20px;
    top: 0px;
    width: calc(50% - 30px);
    height: 50px;
    text-align: center;
    line-height: 50px;
}


/* STANDARD CONTENT */

.titleLabel {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
	position: relative;
}

.subtitleLabel {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 40px);
}

.inputTextData {
	margin-left: 20px;
	margin-top: 20px;
	width: calc(100% - 80px);
	height: 30px;
	line-height: 30px;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	color: white;
	font-size: 16px;
	outline:none;
}

.sliderInput {
    margin-left: 20px;
    margin-top: 20px;
    width: calc(100% - 40px);
    height: 20px;
    cursor: pointer;
}



/* TACTICAL SWITCH */
.tacticalSwitch {
	margin-top: 20px;
	margin-left: 20px;
	height: 50px;
	width: calc(100% - 40px);
}

.switchItem {
	display: inline-block;
	margin-left: 25px;
	height: 50px;
	width: calc(33.33% - 33.33px);
	line-height: 50px;
	text-align: center;
}

.switchItemSelected {
	color: #00ffa2;
	border-bottom: 1px solid #00ffa2;
}

/* PREVIEW */
.smartphoneSimulator {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: calc(100% - 92px);
    max-height: 700px;
}

.smartphoneContent {
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 10px;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
}

.publishButton {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    text-align: center;
    line-height: 50px;
}



/* SWITCH */

.switchContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 20px;
    border-radius: 25px;


    -webkit-animation: containerOn 0.5s;
    animation: containerOn 0.5s;
}

.switchBlob {
    position: absolute;
    height: 16px;
    width: 16px;
    right: 2px;
    top: 2px;

    border-radius: 25px;

    -webkit-animation: blobOn 0.5s;
    animation: blobOn 0.5s;
}

.switchBlobOff {
    right: 32px;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-animation: blobOff 0.5s;
    animation: blobOff 0.5s;
}

.switchContainerOff {
    background-color: transparent;
    -webkit-animation: containerOff 0.5s;
    animation: containerOff 0.5s;
}





/* D&D LINKS */
.linkItem {
    position: relative;
    margin-left: 20px;
    height: 70px;
    width: calc(100% - 40px);
}


.linkSeperatorTop {
    position: absolute;
    top: 0px;
    margin-left: 20px;
    height: 2px;
    width: calc(100% - 40px);
    background-color: #00ffa2;
    display: none;
}

.linkInput {
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    top: 10px;
    padding-left: 205px;
    padding-right: 10px;
    border-radius: 25px;
    height: 40px;
    line-height: 40px;
    width: calc(100% - 215px);
    font-size: 16px;
    background-color: transparent;
    outline:none;
}

.linkSettingsIcon {
    position: absolute;
    right: 40px;
    top: 21px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.deleteIcon {
    position: absolute;
    right: 10px;
    top: 26px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.dragIcon {
    position: absolute;
    left: 10px;
    top: 26px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.linkDescription {
    position: absolute;
    top: 16px;
    left: 80px;
    line-height: 40px;
}

.linkIcon {
    position: absolute;
    left: 40px;
    top: 21px;
    width: 30px;
    height: 30px;
}

.linksList {
	margin-top: 20px;
}

.addLinkButton {
	margin-top: 10px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 50px;
	line-height: 50px;
	text-align: center;
}


/* DETAILS */

.additionalBottomSpacer {
	margin-bottom: 20px;
}

.countLimit {
	position: relative;
}

.countDisplay {
	position: absolute;
	right: 20px;
	top: 0px;
}




/* POP UP STUFF */

.socialOptionContainer {
	position: absolute;
	left: 20px;
	top: 20px;
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	overflow: scroll;
}

.socialOptionItem {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	line-height: 50px;
	height: 50px;
}

.saveSettingsButton {
    position: absolute;
    left: 20px;
    bottom: 20px;
    height: 50px;
    width: calc(100% - 40px);
    line-height: 50px;
    text-align: center;
}

.settingPopUp {
    position: absolute;
    left: 20px;
    top: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 110px);
    overflow: scroll;
}





/* APPEARANCE */
/* THEME SELECTOR */

.themeList {
    position: relative;
    margin-left: 2px;
    margin-top: 30px;
    margin-bottom: 0px;
    width: calc(100% - 4px);
    height: 300px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
}


.themeItem {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    margin-top: 0px;
    width: 150px;
    height: 270px;
    line-height: 260px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.themeItem:last-child {
    margin-right: 20px;
}

.themeItemBackgroundImage {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.themeItemTextLabel {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}



/* COLOR SELECTION */

.colorPicker {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    height: 50px;
    font-size: 16px;
    width: calc(100% - 40px);
}

.colorInputContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: none;
}

.colorInput {
    left: -7px;
    top: -7px;
    border-color: transparent;
    outline:none;
    height: 64px;
    width: 114px;
    position: absolute;
    background-color: transparent;
    padding: 0px;
    border: none;
}

.colorTextVal {
	padding-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    left: 52px;
    width: calc(100% - 114px);
    top: 0px;
    line-height: 30px;
    height: 50px;
    font-size: 16px;
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
}


/* FONT SELECTION */
.dropDownSelector {
    position: relative;
    
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    
}

.dropDownInner {
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: transparent;

    color: white;
    font-size: 16px;
    outline: none;
    border: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.lightLineRight {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 32px;
    height: 100%;
}


@media only screen and (min-width: 800px) {
	.socialOptionContainer {
		position: absolute;
		left: calc(50% - 250px);
		top: calc(50% - 250px);
		width: 500px;
		height: 500px;
	}

    .socialOptionItem {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        margin-left: 20px;
        width: calc(50% - 30px);
        line-height: 50px;
        height: 50px;
        vertical-align: top;
    }

    .settingPopUp {
        position: absolute;
        left: calc(50% - 250px);
        top: calc(50% - 250px);
        width: 500px;
        height: 410px;
    }

    .mobilePublishBar {
        left: 300px;
        width: calc(100% - 300px);
    }
}

@media only screen and (min-width: 1200px) {
    .mobilePublishBar {
        display: none;
    }

}



