
/* DEFAULT TO MOBILE SETUP */

.menu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: none;
	background-color: black;
}

.mobileMenuButton {
	position: fixed;
	width: 30px;
	height: 30px;
	left: 20px;
	top: 30px;
}

.mobileCartButton {
	position: fixed;
	width: 30px;
	height: 30px;
	right: 20px;
	top: 30px;
}

.mobileCartBubble {
	position: fixed;
	right: 15px;
	top: 25px;
	width: 0px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}


.permenantCartBubble {
	position: absolute;
	right: -5px;
	top: 25px;
	width: 0px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}

/*@media only screen and (max-width: 650px) {
	.permenantCartBubble {
		right: -5px;
	}
}
*/

.desktopCartBubble {
	position: absolute;
	left: 25px;
	top: 5px;
	width: 0px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}

.fullMainPage {
	position: fixed;
	top: 70px;
	width: 100%;
	height: calc(100% - 70px);
	left: 0px;
	overflow: scroll;
	background-color: black;
}

.spliteMainPage {
	position: fixed;
	top: 70px;
	width: 100%;
	height: calc(100% - 140px);
	left: 0px;
	overflow: scroll;
	background-color: black;
}

.spliteMainPageCart {
	position: fixed;
	top: 70px;
	width: 100%;
	height: calc(100% - 210px);
	left: 0px;
	overflow: scroll;
	background-color: black;
}

.preview {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: none;

}


/* LARGE DEVICES LIKE IPADS */

@media only screen and (min-width: 800px) {
	.fullMainPage {
		top: 0px;
		height: 100%;
		left: 300px;
		width: calc(100% - 300px);
	}

	.spliteMainPage {
		top: 0px;
		height: calc(100% - 70px);
		left: 300px;
		width: calc(100% - 300px);
	}

	.spliteMainPageCart {
		top: 0px;
		height: calc(100% - 140px);
		left: 300px;
		width: calc(100% - 300px);
	}

	.menu {
		width: 300px;
		border-right: 1px solid rgba(255, 255, 255, 0.25);
		display: block;
	}

	.mobileMenuButton {
		display: none;
	}

	.mobileCartButton {
		display: none;
	}

	.mobileCartBubble {
		display: none;
	}
}


/* DESKTOP */
@media only screen and (min-width: 1200px) {
	.fullMainPage {
		left: calc(50% - 300px);
		width: 900px;
		border-right: 1px solid rgba(255, 255, 255, 0.25);
	}

	.spliteMainPage {
		width: 500px;
		left: calc(50% - 300px);
		height: 100%;
		border-right: 1px solid rgba(255, 255, 255, 0.25);
	}

	.spliteMainPageCart {
		width: 500px;
		left: calc(50% - 300px);
		height: 100%;
		border-right: 1px solid rgba(255, 255, 255, 0.25);
	}

	.preview {
		width: 400px;
		left: auto;
		right: calc(50% - 600px);
		border-right: 1px solid rgba(255, 255, 255, 0.25);
		display: block;
	}

	.menu {
		left: calc(50% - 600px);
		border-left: 1px solid rgba(255, 255, 255, 0.25);
	}

	.mobileMenuButton {
		display: none;
	}

	.mobileCartButton {
		display: none;
	}
}