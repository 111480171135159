

/* AIRDROP STATUS */
.airdropStatus {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.airdropPresent {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
}


/* HEADER */


.titleContainer {
    position: relative;
    width: 100%;
    height: 315px;
    text-align: center;
/*    background-color: rgba(255, 255, 255, 0.1);*/
}

.titleTextVeritcal {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
/*    position: relative;
    top: 50%;
    transform: translateY(-50%);*/
}

.autoLineHeight {
    line-height: 15px;
}

.airdropExplanation {
    position: absolute;
    bottom: 15px;
    width: 350px;
    left: calc(50% - 175px);
    text-align: center;
}


.fullWidthContainer {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;
}

.titleBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.25;
}






/* SEARCH  */

.exploreSearchContainer {
    position: absolute;
    top: 180px;
    left: calc(50% - 260px);
    height: 50px;
    width: 400px;
    vertical-align: bottom;
}

.searchInput {
    position: absolute;
    top: 10px;
    left: 70px;
    width: calc(100% - 90px);
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
}

.searchButton {
    position: absolute;
    top: 180px;
    right: calc(50% - 260px);;
    width: 100px;
    height: 50px;
    line-height: 50px;
    vertical-align: bottom;
    text-align: center;
}



/* SUGGESTIONS */

.suggestionContainer {
    display: inline-block;
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    width: calc(50% - 32px);
    /* width: calc(33.33% - 33.33px);*/
    height: 305px;
}

.sampleImageSuggestion {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
}


.colorFadeImage {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.suggestionItem {
    position: relative;
    line-height: 50px;
    height: 50px;
    width: 100%;
}

.suggestionItemMax {
    position: relative;
    line-height: 50px;
    height: 50px;
    width: 100%;
}

.centralTitle {
    margin-left: 0px;
    width: 100%;
    text-align: center;
}


.interstitialText {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    text-align: center;
}

.interstitialTextRes {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    text-align: left;
    display: none;
}

.addToCartButton {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
}

.suggestionItemText {
    padding-left: 20px;
    /*margin-left: 20px;*/
    width: calc(100% - 171px);
}

.suggestionItemTextTitle {
    position: absolute;
    left: 20px;
    width: calc(100% - 80px);
    line-height: 50px;
}

.lightLineRightAlt {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 151px;
    height: 100%;
}

.suggestionItemPrice {
    position: absolute;
    top: 0px;
    right: 51px;
    width: 100px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.menuItemIconRight {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}


.lightLineRightExplore {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 50px;
    height: 100%;
}

.darkBottomBorder {
    border-bottom: 1px solid black;
}






/* SEARCH RESULT PREVIEW */
.searchPreviewContainer {
    position: absolute;
    top: 303px;
    left: calc(50% - 260px);
    height: 254px;
    width: 400px;
}

.searchPreviewResult {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

.searchPreviewItem {
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
    width: calc(100% - 71px);
}

.searchPreviewStatus {
    display: inline-block;
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
    margin-left: 10px;
    vertical-align: middle;
}

.clickOutOfShader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}



/* CLAIM AIRDROP */
.followableButton {
    position: relative;
    display: inline-block;
    padding-left: 50px;
    padding-right: 20px;
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.nextButton {
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(50% - 30px);
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.previousButton {
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(50% - 30px);
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.scrollableContentInPopUp {
    max-height: calc(100vh - 400px);
    overflow: scroll;
}







/* AIRDROP */
.inputAirdropStyle {
    position: relative;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    
}


.innerInputAirdrop {
    position: absolute;
    left: 70px;
    width: calc(100% - 120px);
    height: 50px;
    color: white;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
}

.checkMark {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    fill: rgba(255, 255, 255, 0.25);
}


.checkPath {
    transform: scale(0.6);
}

/* SIZE ADJUSTMENTS */
@media only screen and (max-width: 1100px) {
    .suggestionContainer {
        width: calc(100% - 40px);
    }
}




@media only screen and (max-width: 800px) {

    .searchPreviewContainer {
        left: 20px;
        width: calc(100% - 40px);
    }

}

@media only screen and (max-width: 600px) {
    .exploreSearchContainer {
        position: absolute;
        left: 20px;
        width: calc(100% - 164px);
    }

    .searchButton {
        right: 20px;
    }
}




@media only screen and (max-width: 425px) {

    .titleTextVeritcal {
        padding-top: 40px;
    }
    .exploreSearchContainer {
        top: 120px;
        width: calc(100% - 40px);
    }
    .searchButton {
        width: calc(100% - 40px);
        top: 190px;
    }
    .searchPreviewContainer {
        top: 240px;
    }
}














