.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

td {
  padding: 5px;
}