

.mainTitle {
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	height: 52px;
	line-height: 52px;
}

.contentContainer {
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	min-height: 50px;
}

.tutorialVideo {
	margin-top: 20px;
	margin-left: 20px;
	width: calc(100% - 42px);
	height: 178.875px;
}

.saveSettingsDesktop {
	display: none;
	position: absolute;
	right: 0px;
	top:  0px;
	height: 50px;
	padding-right: 20px;
	padding-left: 20px;
}

.sideButtonRegular {
	display: block;
	position: absolute;
	right: 0px;
	top:  0px;
	height: 50px;
	padding-right: 20px;
	padding-left: 20px;
}

.saveSettingsMobile {
	position: absolute;
	bottom: 20px;
	left: 20px;
	height: 50px;
	width: calc(100% - 40px);
	text-align: center;
	line-height: 50px;
}

@media only screen and (min-width: 1200px) {
	.saveSettingsDesktop {
		display: block;
	}
}