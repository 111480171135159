/* DEFAULT TO MOBILE SETUP */
.logoImage {
	position: fixed;
	left: calc(50% - 25px);
	top: 20px;
	width: 50px;
	height: 50px;
	object-fit: contain;
}

.logoText {
	position: fixed;
	top: 20px;
	height: 50px;
	line-height: 50px;
	display: none;
	font-family: 'Quantico', sans-serif;
	font-size: 30px;
}

.extraShade {
	position: fixed;
	top: 70px;
	width: 100%;
	height: 20px;
	background-image: linear-gradient(to bottom, black, transparent);
}

.extraShadeBottom {
	position: fixed;
	bottom: 70px;
	width: 100%;
	height: 20px;
	background-image: linear-gradient(to top, black, transparent);
}

.extraShadeBottomCart {
	position: fixed;
	bottom: 140px;
	width: 100%;
	height: 20px;
	background-image: linear-gradient(to top, black, transparent);
}

.mobileAirdropIndicator {
	position: fixed;
	top:  30px;
	right: 60px;
	width: 0px;
	height: 30px;
	line-height: 30px;
	text-align: center;
}



/* LARGE DEVICES LIKE IPADS */
@media only screen and (min-width: 800px) {
	.logoImage {
		top: 21px;
		left: 20px;
	}

	.logoText {
		top: 21px;
		left: 90px;
		display: block;
	}

	.extraShade {
		display: none;
	}

	.mobileAirdropIndicator {
		display: none;
	}

	.extraShadeBottom {
		left: 301px;
		width: calc(100% - 301px);
	}

}

/* DESKTOP */
@media only screen and (min-width: 1200px) {
	.logoImage {
		left: calc(50% - 580px);
	}

	.logoText {
		left: calc(50% - 510px);
	}

	.extraShadeBottomCart {
		display: none;
	}

	.extraShadeBottom {
		display: none;
	}
}