


.searchContainerResults {
	vertical-align: top;
	display: inline-block;
	position: relative;
	margin-top: 20px;
	margin-left: 20px;
	height: 50px;
	width: calc(100% - 164px);
}

.resultsSearchButton {
	display: inline-block;
	position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: 100px;
    height: 50px;
    line-height: 50px;
    vertical-align: bottom;
    text-align: center;
}

.domainListItem {
	position: relative;
}


/* REAL RESULT */

.resultContainer {
	display: inline-block;
	position: relative;
	margin-left: 20px;
	margin-top: 20px;
	height: 264px;
	width: calc(100% - 331px);
}

.resultItemImage {
	display: inline-block;
	margin-left: 20px;
	margin-top: 20px;
	width: 264px;
	height: 264px;
	object-fit: contain;
}

.resultImageLoading {
	width: 30px; height: 30px; position: absolute; top: 209px; left: 132px; display: none;
}

.resultItemImageDelimatator {
	position: absolute;
	width: 1px;
	left: 122px;
	height: 100%;
}

.resultItemTitleText {
	position: absolute;
	left: 20px;
	top: 20px;
	width: calc(100% - 40px);
	height: 50px;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.resultLeftBottomText {
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: 102px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	display: none;
}

.resultItemDescription {
	position: absolute;
	top: 70px;
	left: 20px;
	width: calc(100% - 40px);
	height: 30px;
}

.resultItemFullDesc {
	position: absolute;
	top: 100px;
	left: 20px;
	width: calc(100% - 40px);
}

.dropDownSelectorSearchResults {
    position: absolute;
    bottom: 90px;
    left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    display: none;
    
}

.addToCartMain {
	position: absolute;
    bottom: 20px;
	left: 143px;
	height: 50px;
	line-height: 50px;
	width: calc(100% - 162px);
	text-align: center;
	display: none;
}

.ownerAddress {
	position: absolute;
	left: 20px;
	top: 110px;
	display: none;
}

.marketplaceButton {
	position: absolute;
	left: 20px;
	bottom: 20px;
	height: 50px;
	width: 152px;
	display: none;
}

.marketplaceItemSearchResults {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
}

.marketplaceItemIconSearchResults {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 30px;
	height: 30px;
}


/*.marketplaceItem {
	display: inline-block;
	margin-top: 10px;
	margin-left: 10px;
	width: 30px;
	height: 30px;
}
*/
/* SUGGESTIONS */
/*.domainListContainer {
	margin-top: -5px;
}*/

.addToCartButton {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
}

.menuItemIconRight {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.searchPreviewItem {
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
    width: calc(100% - 71px);
}

.searchPreviewStatus {
    display: inline-block;
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
    margin-left: 10px;
    vertical-align: middle;
}








/* SEARCH RESULT PREVIEW */
.searchPreviewContainerResults {
    position: absolute;
    top: 92px;
    left: 20px;
    height: 254px;
    width: calc(100% - 164px);
}

.searchPreviewResult {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

.searchPreviewItem {
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
    width: calc(100% - 71px);
}

.searchPreviewStatus {
    display: inline-block;
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
    margin-left: 10px;
    vertical-align: middle;
}

.clickOutOfShader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}


.lightLineRightSearch {
	position: absolute;
	right: 50px;
}




.interstitialText {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    /*text-align: center;*/
}

@media only screen and (max-width: 930px) {
	.resultContainer {
		width: calc(100% - 40px);
	}
	.resultItemImage {
		display: none;
	}
	.resultImageLoading {
		display: none;
	}
}

@media only screen and (max-width: 800px) {
	.resultContainer {
		width: calc(100% - 331px);
	}

	.resultItemImage {
		display: inline-block;
	}

	.resultImageLoading {
		display: block;
	}


	.searchPreviewContainerResults {
	    width: calc(100% - 40px);
	}

}

@media only screen and (max-width: 630px) {
	.resultContainer {
		width: calc(100% - 40px);
	}
	.resultItemImage {
		display: none;
	}

	.resultImageLoading {
		display: none;
	}
}
