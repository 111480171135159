.globalShader {
    z-index: 100000;
    top: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    background-color: rgba(0, 0, 0, 0.75);

    /*background: rgba(0, 0, 0, 0.75);*/
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.menuShader {
    z-index: 100000;
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    background-color: rgba(0, 0, 0, 0.75);
}


.mainPageShader {
    z-index: 100000;
    width: calc(100% - 2px);
    height: 100%;
    display: none;
    top: 0px;
    left: 2px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    /*background-color: red;*/
}

.popUpCloser {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.closeBigXButton {
    position: absolute;
/*    top: -25px;
    left: -25px;
    width: 50px;
    height: 50px;*/

    top: -15px;
    left: -15px;
    width: 40px;
    height: 40px;
}

.innerXImg {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
}
/*
@media only screen and (max-width: 800px) {
    .closeBigXButton {
        top: -15px;
        left: -15px;
        width: 40px;
        height: 40px;
    }
}*/




.loadingInnerPopUpShader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 39, 43, 0.75);
    display: none;
}

.loadingInnerPopUpSpinner {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
}






