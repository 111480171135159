.searchContainer {
    display: inline-block;
    position: relative;
    /*margin-top: 20px;*/
    margin-left: 20px;
    height: 50px;
    width: calc(100% - 250px);
    vertical-align: bottom;
}

.searchInput {
    position: absolute;
    top: 10px;
    left: 70px;
    width: calc(100% - 90px);
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
}

.sortDropDown {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    margin-top: 20px;
    width: 82px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.filterDropDown {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    margin-top: 20px;
    width: 82px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}


.batchActionDropDown {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    margin-top: 20px;
    width: 82px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}


.filterSelectorListItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: relative;
}

.filterSelectorMenu {
    position: absolute;
    top: 163px;
    right: 19px;
    width: 260px;
    height: 152px;
    display: none;
}

.filterCheckBox {
    position: absolute;
    right: 0px;
    width: 50px;
    height: 50px;
}

.lightLineRightSearch {
    position: absolute;
    top: 0px;
    width: 1px;
    right: 50px;
    height: 100%;
}

.sortSelectorListItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: relative;
}

.batchActionSelectorListItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: relative;
}

.sortingSelectorMenu {
    position: absolute;
    top: 163px;
    right: 124px;
    width: 260px;
    height: 101px;
    display: none;
}

.batchActionSelectorMenu {
    position: absolute;
    top: 163px;
    right: 229px;
    width: 260px;
    height: 50px;
    display: none;
}


/* BATCH SEARCH */
.batchSearchContainer {
    display: inline-block;
    position: relative;
    margin-left: 20px;
    height: 50px;
    width: calc(100% - 352px);
    vertical-align: bottom;
}

.lightLineExtra {
    position: absolute;
    width: 1px;
    left: 101px;
    height: 100%;
}

.menuItemIconExtra {
    position: absolute;
    left: 61px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.searchInputExtra {
    position: absolute;
    top: 10px;
    left: 122px;
    width: calc(100% - 142px);
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
}

.batchSelector {
    position: absolute;
    left: 0ox;
    top: 0px;
    width: 50px;
    height: 100%;
}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 600px) {
    .searchContainer {
        margin-top: 20px;
        width: calc(100% - 40px);
    }

    .batchSearchContainer {
        margin-top: 20px;
        width: calc(100% - 40px);
    }


    .filterSelectorMenu {
        /*right: auto;
        left: 122px;
        top: 165px;*/

        right: auto;
        top: 237px;
        left: 20px;
        width: calc(100% - 40px);
    }

    .sortingSelectorMenu {
        /*right: auto;
        left: 20px;
        top: 165px;*/

        right: auto;
        top: 237px;
        left: 20px;
        width: calc(100% - 40px);
    }

    .batchActionSelectorMenu {
        /*right: auto;
        left: 20px;
        top: 165px;*/

        right: auto;
        top: 237px;
        left: 20px;
        width: calc(100% - 40px);
    }
}


